import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTracking } from 'react-tracking';

const ButtonTracker = ({ children }) => {
  const { trackEvent } = useTracking();
  const handleButtonClick = useCallback(
    (pointerEvent) => {
      let { target } = pointerEvent;
      let parentCounter = 0;
      while (target !== null && parentCounter < 4) {
        if (target.nodeName === 'BUTTON' && target.id) {
          trackEvent({
            action: 'CLICK',
            button: target.id,
          });
          break;
        }
        target = target.parentNode;
        parentCounter += 1;
      }
    },
    [trackEvent],
  );

  useEffect(() => {
    window.addEventListener('click', handleButtonClick, false);
    return () => window.removeEventListener('click', handleButtonClick, false);
  }, [handleButtonClick]);

  return children;
};

ButtonTracker.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ButtonTracker;
