/* eslint-disable max-len */
import {
  ENROLLMENT_ERROR_MESSAGES,
  FAILED_ENROLLMENT,
  SUCCESSFUL_ENROLLMENT,
} from '../constants/enrollment';

export const enrollmentResolution = (resolution) => (resolution === 'success' ? SUCCESSFUL_ENROLLMENT : FAILED_ENROLLMENT);

export const enrollmentErrorMessage = (message) => ENROLLMENT_ERROR_MESSAGES.find(({ error }) => error === message).message;
