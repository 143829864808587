import React, { useCallback, useState } from 'react';
import { Box, Container, IconButton, Stack, Typography } from '@mui/material';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { BackSide, Flippy, FrontSide } from 'react-flippy';
import { useReactiveVar } from '@apollo/client';
import EnrollmentForm from '../EnrollmentForm';
import { Button, Title } from '../Layout';
import TermsAndConditionsDocument from '../TermsAndConditionsDocument';
import { TERMS_AND_CONDITIONS_TITLE } from '../../constants/terms-and-conditions';
import AcceptedTermsForm from '../forms/AcceptedTermsForm';
import { MOBILE_TYC_REDIRECT_TITLE } from '../../constants/enrollment';
import { acceptedTermsVar } from '../../graphql/reactive-variables';

const EnrollmentMobile = () => {
  const [flipped, toggleFlipped] = useState(false);
  const checked = useReactiveVar(acceptedTermsVar);

  const toggleFlip = useCallback(() => {
    toggleFlipped(!flipped);
  });

  return (
    <Container variant="mobile">
      <Flippy
        flipOnClick={false}
        isFlipped={flipped}
        style={{
          margin: 0,
          boxShadow: 'none',
          padding: 0,
          height: '100%',
        }}
      >
        <FrontSide
          style={{
            padding: '0px',
            boxShadow: 'none',
            height: '100%',
            alignContent: 'center',
          }}
        >
          <Stack
            alignItems="center"
            direction="column"
            py={4}
          >
            <Typography
              textAlign="center"
              justifyContent="center"
              variant="secondary"
              sx={{ wordWrap: 'break-word',
                lineHeight: 1.4,
                textDecoration: 'underline',
                visibility: 'visble',
                cursor: 'pointer',
              }}
              width="300px"
              onClick={toggleFlip}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {MOBILE_TYC_REDIRECT_TITLE}
                <ChevronRight fontSize="medium" />
              </Box>
            </Typography>
            <EnrollmentForm />
          </Stack>
        </FrontSide>
        <BackSide
          style={{
            padding: '4px',
            boxShadow: 'none',
            display: 'flex',
            height: '100%',
          }}
        >
          <Stack
            alignItems="center"
            height="100%"
            width="100%"
            spacing={2}
            p="4px"
          >
            <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
              <Title title={TERMS_AND_CONDITIONS_TITLE} alignSelf="center" />
              <IconButton onClick={toggleFlip} color="secondary">
                <ChevronLeft fontSize="medium" />
              </IconButton>
            </Stack>
            <TermsAndConditionsDocument />
            <AcceptedTermsForm />
            <Button
              id="next-tyc"
              disabled={!checked}
              onClick={toggleFlip}
              label="Continuar"
              sx={{ mt: '20px !important',
                minHeight: '48px',
                width: '80%',
                fontSize: '16px',
              }}
            />
          </Stack>
        </BackSide>

      </Flippy>
    </Container>

  );
};

export default EnrollmentMobile;
