import { useEffect } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_USER } from '../graphql/queries';
import { refreshTokenVar } from '../graphql/reactive-variables';

const useGetUser = () => {
  const refreshToken = useReactiveVar(refreshTokenVar);
  const { data, loading, refetch, error } = useQuery(GET_USER, {
    onError: () => setTimeout(refetch, 1500),
  });

  useEffect(() => {
    if (!error && refreshToken && !loading && !data?.getUser) {
      refetch();
    }
  }, [loading, refreshToken, data]);

  return data?.getUser;
};

export default useGetUser;
