import { gql } from '@apollo/client';

export const TermsAndConditionsFields = gql`
  fragment TermsAndConditionsFields on TermsAndConditionsType {
    id
    sections {
      id
      text
      sectionType
    }
  }
`;

export const EnrollmentCredentialFields = gql`
  fragment EnrollmentCredentialFields on CredentialType {
    id
    source
    credentialProvider {
      id
      credentialProviderType {
        id
        name
      }
    }
  }
`;

export const UserEnrolledFields = gql`
  fragment UserEnrolledFields on UserType {
    id
    firstName
    lastName
    selectedCompany {
      id
      hasLendingAcceptedterms @client
      currentUserAcceptedTerms {
        id
        product
        requestingPlatform {
          id
          code
        }
      }
      rut
      hasSiiCredentials @client
      validCredentials {
        ...EnrollmentCredentialFields
      }
      siiCredentials @client {
        ...EnrollmentCredentialFields
      }
    }
  }
  ${EnrollmentCredentialFields}
`;
