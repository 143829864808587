import React from 'react';
import { Box, Container } from '@mui/material';
import FingoLoader from '../../assets/gifs/fingo-loader.gif';

const Spinner = () => (
  <Container
    variant="primary"
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      justifySelf: 'center',
    }}
  >
    <Box
      component="img"
      src={FingoLoader}
      alt="Loading..."
      sx={{
        maxWidth: '400px',
        width: '100%',
        height: 'auto',
      }}
    />
  </Container>
);

export default Spinner;
