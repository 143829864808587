import jwtDecode from 'jwt-decode';
import { ApolloLink, HttpLink, concat, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { refreshTokenVar, tokenVar } from './reactive-variables';
import { refreshBody } from '../constants/auth';
import { login } from '../helpers/auth';

const customFetch = async (uri, options) => {
  const token = tokenVar();
  if (jwtDecode(token).exp * 1000 < Date.now()) {
    const refreshRequest = await fetch(uri, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(refreshBody(refreshTokenVar())),
    });
    const refreshResponse = await refreshRequest.json();
    const { refreshToken } = refreshResponse.data;
    login(refreshToken);
    const refreshedOptions = {
      ...options,
      headers: { ...options.headers, authorization: `JWT ${refreshToken.token}` },
    };
    return fetch(uri, refreshedOptions);
  }
  return fetch(uri, options);
};

export const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_SERVER_URI,
  credentials: 'include',
});

export const refreshHttpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_SERVER_URI,
  fetch: customFetch,
  credentials: 'include',
});

export const authLink = new ApolloLink((operation, forward) => {
  const token = tokenVar();

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : null,
    },
  }));

  return forward(operation);
});

const splitLink = split(({ query }) => {
  const { operation, name } = getMainDefinition(query);
  return operation === 'mutation' && name.value === 'santanderAuth';
}, httpLink, concat(authLink, refreshHttpLink));

export default splitLink;
