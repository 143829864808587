import Cookies from 'js-cookie';
import { refreshTokenVar, tokenVar } from '../graphql/reactive-variables';

export const getCookie = (name) => Cookies.get(name);

export const setCookie = (name, value) => {
  Cookies.set(name, value);
  return value;
};

export const removeCookie = (name) => {
  Cookies.remove(name);
  return null;
};

export const login = ({ token, refreshToken }) => {
  tokenVar(setCookie('token', token));
  refreshTokenVar(setCookie('refreshToken', refreshToken));
};

export const logout = () => {
  tokenVar(removeCookie('token'));
  refreshTokenVar(removeCookie('refreshToken'));
};
