import React from 'react';
import { render } from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';
import client from './graphql';
import TrackerProvider from './components/TrackerProvider';
import Router from './components/Router';
import useHistory from './hooks/useHistory';

const EnrollmentWidget = () => {
  const history = useHistory();
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <TrackerProvider frontendName="enrollment-widget">
            <App />
          </TrackerProvider>
        </Router>
      </ThemeProvider>
    </ApolloProvider>
  );
};

render(<EnrollmentWidget />, document.getElementById('root'));
