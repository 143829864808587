import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';

const Title = ({ title, ...props }) => (
  <Stack direction="row" spacing={0.75} {...props} useFlexGap flexWrap="wrap">
    {title.map(({ text, color, variant, letterSpacing }) => (
      <Typography
        key={text}
        variant={variant}
        fontWeight={500}
        color={color}
        letterSpacing={letterSpacing}
      >
        {text}
      </Typography>
    ))}
  </Stack>
);

Title.propTypes = {
  title: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.oneOf(['primary', 'secondary']).isRequired,
    }),
  ).isRequired,
};

export default Title;
