import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import PropTypes from 'prop-types';
import ButtonTracker from './ButtonTracker';
import useReactTrackingLayer from '../hooks/useReactTrackingLayer';
import useHistory from '../hooks/useHistory';
import useGetUser from '../hooks/useGetUser';

const TrackerProvider = ({ children, frontendName }) => {
  const dispatch = useReactTrackingLayer(frontendName);
  const history = useHistory();
  const location = useLocation();
  const user = useGetUser();
  const domain = window.location.hostname;

  const { Track, trackEvent } = useTracking(
    {
      domain,
      route: location.pathname,
      searchParams: location.search,
      company: user?.selectedCompany,
    },
    { dispatch },
  );

  useEffect(() => {
    trackEvent({ action: 'LOADED' });
    const unlisten = history.listen((newLocation, b) => {
      if (b === 'PUSH') {
        trackEvent({
          route: newLocation.pathname,
          searchParams: newLocation.search,
          action: 'LOADED',
        });
      }
    });
    return () => unlisten();
  }, []);

  return (
    <Track>
      <ButtonTracker>{children}</ButtonTracker>
    </Track>
  );
};

TrackerProvider.propTypes = {
  children: PropTypes.node.isRequired,
  frontendName: PropTypes.string.isRequired,
};

export default TrackerProvider;
