import { gql } from '@apollo/client';
import { UserEnrolledFields } from './fragments';

export const refreshTokenMutation = `mutation refreshToken($refreshToken: String!) {
  refreshToken(refreshToken: $refreshToken) {
    token
    refreshToken
    payload
  }
}`;

export const USER_INTERACTION_REGISTER = gql`
  mutation userInteractionRegister($action: UserInteractionInputType!) {
    userInteractionRegister(action: $action) {
      interaction {
        id
      }
    }
  }
`;

export const SANTANDER_AUTH = gql`
  mutation santanderAuth($token: String!) {
    santanderAuth(token: $token) {
      user {
        id
      }
      token
      refreshToken
    }
  }
`;

export const UPLOAD_CREDENTIALS = gql`
  mutation uploadCredentials(
    $username: String!
    $password: String!
    $companyId: Int
  ) {
    uploadCredentials(
      username: $username
      password: $password
      companyId: $companyId
      credentialProvider: 1
      source: "Santander"
    ) {
      getUser {
        ...UserEnrolledFields
      }
    }
  }
  ${UserEnrolledFields}
`;
