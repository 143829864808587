import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { tokenVar } from '../graphql/reactive-variables';

const PrivateRoutes = () => {
  const token = useReactiveVar(tokenVar);

  return token ? <Outlet /> : <Navigate to="/loading" />;
};

export default PrivateRoutes;
