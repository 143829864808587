const components = {
  MuiAlert: {
    styleOverrides: {
      root: {
        height: '58px',
        width: '268px',
      },
      icon: {
        margin: 'auto',
        padding: 0,
        fontSize: '24px',
      },
      message: {
        margin: 'auto',
        marginLeft: 0,
      },
    },
  },
  MuiAlertTitle: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.secondary,
        fontSize: '14px',
        lineHeight: '18px',
      }),
    },
  },
  MuiButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: theme.typography.fontFamily,
        borderRadius: '40px',
        height: '36px',
        width: '220px',
      }),
    },
    variants: [
      {
        props: { variant: 'primaryMobile' },
        style: () => ({
          width: '100%',
          height: '100%',
          padding: '15px 0px 15px 0px',
        }),
      },
    ],
  },
  MuiContainer: {
    variants: [
      {
        props: { variant: 'primary' },
        style: ({ theme }) => ({
          width: '100%',
          height: '96vh',
          // maxWidth: 1076, //Ask if Max Width is needed
          padding: '0px 0px 10px 0px',
          backgroundColor: theme.palette.white.main,
          justifyContent: 'center',
        }),
      },
      {
        props: { variant: 'secondary' },
        style: ({ theme }) => ({
          width: 822,
          height: 272,
          padding: '16px 23px 37px 24px',
          backgroundColor: theme.palette.white.main,
        }),
      },
      {
        props: { variant: 'mobile' },
        style: ({ theme }) => ({
          width: '100%',
          height: '96vh',
          padding: '0px',
          backgroundColor: theme.palette.white.main,
          alignContent: 'center',
        }),
      },
    ],
  },
  MuiFilledInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.inputText.main,
        backgroundColor: theme.palette.inputBackground.main,
        border: `1px solid ${theme.palette.inputBorder.light}`,
        borderRadius: '4px 4px 0 0',
        borderBottom: `1px solid ${theme.palette.inputBorder.main}`,
        fontSize: '16px',
        width: '300px',
        height: '48px',
        '&:before': {
          borderBottom: 'none !important',
        },
        '&:after': {
          borderBottom: 'none',
        },
        '&:hover': {
          backgroundColor: theme.palette.inputBackground.main,
          borderBottom: `1px solid ${theme.palette.inputBorder.main}`,
        },
        '&.Mui-disabled': {
          backgroundColor: theme.palette.inputBackground.main,
        },
        '&.Mui-disabled:before': {
          borderBottom: 'none',
        },
      }),
      input: ({ theme }) => ({
        fontSize: '16px',
        color: theme.palette.secondary.main,
      }),
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        '& .MuiFormControlLabel-label': {
          fontWeight: 'bold',
          lineHeight: '29px',
        },
      },
    },
  },
  MuiGrid: {
    variants: [
      {
        props: { variant: 'terms-and-conditions' },
        style: ({ theme }) => ({
          width: '100%',
          height: '100%',
          backgroundColor: theme.palette.gray.main,
          marginTop: '16px !important',
          padding: '19px 17px 0px 42px',
          flexDirection: 'column',
          flexWrap: 'nowrap',
          alignItems: 'flex-start',
          overflow: 'scroll',
        }),
      },
      {
        props: { variant: 'terms-and-conditions-mobile' },
        style: ({ theme }) => ({
          width: '100%',
          height: 'auto',
          backgroundColor: theme.palette.gray.main,
          marginTop: '16px !important',
          padding: '19px 17px 0px 42px',
          flexDirection: 'column',
          flexWrap: 'nowrap',
          alignItems: 'flex-start',
          overflow: 'scroll',
        }),
      },
      {
        props: { variant: 'terms-and-conditions-section' },
        style: {
          marginBottom: '30px !important',
        },
      },
    ],
  },
  MuiInputLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        fontSize: '12px',
        transition: 'none',
        '&.Mui-focused': {
          color: theme.palette.inputBorder.main,
        },
      }),
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: ({ theme }) => ({
        height: '57px',
        backgroundColor: theme.palette.white.main,
        boxShadow: 'none',
      }),
    },
  },
  MuiSvgIcon: {
    variants: [
      {
        props: { variant: 'resolution' },
        style: { fontSize: '102px' },
      },
      {
        props: { variant: 'go-back' },
        style: { fontSize: '19px' },
      },
      {
        props: { variant: 'go-next' },
        style: { fontSize: '102px' },
      },
      {
        props: { variant: 'go-next-disabled' },
        style: { color: 'rgba(0, 0, 0, 0.26)' },
      },
    ],
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        overflow: 'inherit',
      },
    },
    variants: [
      {
        props: { variant: 'subtitle' },
        style: ({ theme }) => ({
          fontFamily: theme.typography.fontFamily,
          fontSize: '16px',
          fontWeight: 'bold',
        }),
      },
      {
        props: { variant: 'paragraph' },
        style: ({ theme }) => ({
          fontFamily: theme.typography.fontFamily,
          fontSize: '16px',
        }),
      },
      {
        props: { variant: 'form' },
        style: ({ theme }) => ({
          fontFamily: theme.typography.fontFamily,
          color: theme.palette.secondary.main,
          fontSize: '16px',
          fontWeight: 'normal !important',
        }),
      },
      {
        props: { variant: 'secondary' },
        style: ({ theme }) => ({
          fontFamily: theme.typography.fontFamily,
          color: theme.palette.inputText.secondary,
          fontSize: '18px',
          fontWeight: '500',
        }),
      },
    ],
  },
};

export default components;
