const typography = {
  fontFamily: [
    'Santander Text W05 Regular',
    'Ubuntu',
    'Roboto',
    'Helvetica',
    'Arial',
    'sans-serif',
  ].join(','),
  h1: { fontSize: '28px' },
  h2: { fontSize: '24px' },
  body1: { fontSize: '18px' },
  body2: { fontSize: '16px' },
  caption: { fontSize: '14px' },
  input: { fontSize: '12px' },
  h3: undefined,
  h4: undefined,
  h5: undefined,
  h6: undefined,
  subtitle1: undefined,
  subtitle2: undefined,
  button: undefined,
  overline: undefined,
};

export default typography;
