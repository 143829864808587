import { InMemoryCache } from '@apollo/client';

const cache = new InMemoryCache({
  typePolicies: {
    CompanyType: {
      fields: {
        siiCredentials: {
          read(_, { readField }) {
            const credentials = readField('validCredentials');
            if (!credentials) return [];
            return credentials.filter((cred) => {
              const source = readField('source', cred);
              const credentialProvider = readField('credentialProvider', cred);
              const credentialProviderType = readField(
                'credentialProviderType',
                credentialProvider,
              );
              const credentialProviderTypeName = readField(
                'name',
                credentialProviderType,
              );
              return (
                credentialProviderTypeName === 'government'
                && source === 'Santander'
              );
            });
          },
        },
        hasLendingAcceptedterms: {
          read(_, { readField }) {
            const terms = readField('currentUserAcceptedTerms');
            if (!terms) return false;
            const lendingTerms = terms.filter((ref) => {
              const product = readField('product', ref);
              const requestingPlatform = readField('requestingPlatform', ref);
              const requestingPlatformCode = readField(
                'code',
                requestingPlatform,
              );
              return (
                product === 'LENDING' && requestingPlatformCode === 'SANTANDER'
              );
            });
            return lendingTerms.length > 0;
          },
        },
        hasSiiCredentials: {
          read(_, { readField }) {
            const credentials = readField('siiCredentials');
            return credentials.length > 0;
          },
        },
      },
    },
  },
});

export default cache;
