import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Enrollment, EnrollmentResolution } from './pages';
import PrivateRoutes from './components/PrivateRoutes';
import useAuthentication from './hooks/useAuthentication';
import Spinner from './components/Layout/Spinner';

const App = () => {
  const { loading } = useAuthentication();

  if (loading) return <Spinner />;

  return (
    <Routes>
      <Route path="/enrollment/failed" element={<EnrollmentResolution resolution="failed" />} />
      <Route path="/loading" element={<Spinner />} />
      <Route path="/" element={<PrivateRoutes />}>
        <Route path="/enrollment" element={<Enrollment />} />
        <Route path="/enrollment/success" element={<EnrollmentResolution resolution="success" />} />
      </Route>
    </Routes>
  );
};

export default App;
