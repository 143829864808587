import { gql } from '@apollo/client';
import { TermsAndConditionsFields, UserEnrolledFields } from './fragments';

export const GET_USER = gql`
  query GetUser {
    getUser {
      ...UserEnrolledFields
    }
  }
  ${UserEnrolledFields}
`;

export const TERMS_AND_CONDITIONS = gql`
  query termsAndConditions($countryName: String!) {
    termsAndConditions(last: 1, countryName: $countryName) {
      edges {
        node {
          ...TermsAndConditionsFields
        }
      }
    }
  }
  ${TermsAndConditionsFields}
`;
