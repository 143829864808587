import { useMutation } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { tokenVar } from '../graphql/reactive-variables';
import { USER_INTERACTION_REGISTER } from '../graphql/mutations';

const useReactTrackingLayer = (frontendName) => {
  const landingUniqueHash = useMemo(() => Math.random().toString(36).substring(2), []);
  const [registerAction] = useMutation(USER_INTERACTION_REGISTER, {
    onError: () => {},
  });
  const dispatcher = useCallback(
    (trackingEvent) => {
      const { button, action, domain,
        route, searchParams, company, isMobile = false } = trackingEvent;
      if (tokenVar()) {
        registerAction({
          variables: {
            action: {
              frontendName,
              domain,
              route,
              searchParams,
              company: company?.id,
              isMobile,
              action,
              button,
              session: landingUniqueHash,
            },
          },
        });
      }
    },
    [registerAction, frontendName, landingUniqueHash],
  );
  return dispatcher;
};

export default useReactTrackingLayer;
